import {Dispatch} from 'redux'
import {AppState} from '../../store/AppState'
import {fetchTodayAnalysis} from '../../store/today/actions'
import {connect} from 'react-redux'
import TodayContainer from './today-container'

// const mapStateToProps = ({owner}: AppState) => ({
//   owner: owner
// });

const mapStateToProps = ({today}: AppState) => ({
  todayAnalysis: today.todayAnalysis,
  isLoading: today.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTodayAnalysis: () => dispatch(fetchTodayAnalysis()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TodayContainer)
