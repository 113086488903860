import React from 'react'
import {radarAndPieBox} from './today.module.scss'
import OwnerInfoBox from '../input-box/owner-input-box'
import {TodayAnalysis} from '../../types/saJoo/TodayAnalysis'
import {
  OhHangPieChart,
  transformOhHangData,
} from '../common/ohhang-pie-chart/ohhang-pie-chart'
import EmptySpace from '../common/EmptySpace'
// @ts-ignore
import AdSense from 'react-adsense'
import {Element} from 'react-scroll/modules'
import {CircularProgress} from '@material-ui/core'
import SEO from '../seo'
import {VictoryLineGraph} from '../common/score-line-graphs/VictoryLineGraph'
import {Container} from '../../styles/common'
import SetupAnalysis from '../common/SetupAnalysis'
import SaJooAds from "../common/ads/sajoo-ads";

interface Props {
  todayAnalysis?: TodayAnalysis
  fetchTodayAnalysis: () => void
  isLoading: boolean
}

// 메인 페이지에 사용될 오늘의 운세 컨테이너
const TodayContainer: React.FC<Props> = (props) => {
  const {todayAnalysis, fetchTodayAnalysis, isLoading} = props
  return (
    <div>
      <SEO
        title={'무료 오늘의 운세'}
        description={
          '전문 명리학자가 사용하는 8-codes.com의 정통 사주 명리 분석 프로그램을 기반으로 하여 정밀한 무료 오늘의 운세를 제공합니다.'
        }
      />
      <section className="pt-10 sm:pt-20 mb-40 mt-10">
        <div
          className={
            'container max-w-screen-xl mx-auto px-8 lg:flex lg:items-center'
          }
        >
          <div className="text-center  lg:w-1/2">
            <h1 className="text-5xl lg:text-6xl xl:text-6xl font-bold leading-none">
              오늘의 운세
            </h1>
            <h2 className="text-xl sm:text-2xl  font-semibold text-gray-500">
              오늘의 운세 분석은 무료입니다.
            </h2>

            <OwnerInfoBox />
            <button
              className={
                'block mx-auto mb-2  w-64  py-3    bg-blue-400 hover:bg-blue-500 rounded text-white'
              }
              style={{width: 300}}
              onClick={() => {
                if (!isLoading) {
                  fetchTodayAnalysis()
                }
              }}
            >
              {isLoading ? (
                <CircularProgress color={'inherit'} size={20} disableShrink />
              ) : (
                '오늘의 운세 분석'
              )}
            </button>
          </div>
          <div className="lg:w-1/2">
            <div className="mx-auto max-w-2xl">
              <SetupAnalysis />
            </div>
          </div>
        </div>
      </section>

      <Container>
        <Element name={'after-load'} />
        {/*<AdSense.Google*/}
        {/*  client="ca-pub-5894919272228059"*/}
        {/*  slot="8397580173"*/}
        {/*  style={{display: 'block'}}*/}
        {/*  format="auto"*/}
        {/*  responsive="true"*/}
        {/*  layoutKey="-gw-1+2a-9x+5c"*/}
        {/*/>*/}
        <SaJooAds/>
        {todayAnalysis ? (
          <div>
            <div className={radarAndPieBox}>
              {/*<div>*/}
              {/*  <h2>오늘의 종합 점수</h2>*/}
              {/*  <div style={{width: 300, height: 300}}>*/}
              {/*    <SibSungRadar data={transRadarData(todayAnalysis.radar)}/>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div>
                <h2>오늘의 오행 분포</h2>
                <div style={{width: 300, height: 300}}>
                  <OhHangPieChart
                    data={transformOhHangData(todayAnalysis.ohHangPoint)}
                  />
                </div>
              </div>
            </div>
            <div className={'divider'} />
            <div>
              <h1>시간별 지수</h1>
              <h2>자아</h2>
              <VictoryLineGraph
                title={'자아'}
                data={todayAnalysis.egoAnalysis.scores}
                tickType={'hour'}
              />

              <h2>활동</h2>
              <VictoryLineGraph
                title={'활동'}
                data={todayAnalysis.actAnalysis.scores}
                tickType={'hour'}
              />

              <h2>성취</h2>
              <VictoryLineGraph
                title={'성취'}
                data={todayAnalysis.achieveAnalysis.scores}
                tickType={'hour'}
              />

              <h2>명예</h2>
              <VictoryLineGraph
                title={'명예'}
                data={todayAnalysis.honorAnalysis.scores}
                tickType={'hour'}
              />

              <h2>공부</h2>
              <VictoryLineGraph
                title={'공부'}
                data={todayAnalysis.studyAnalysis.scores}
                tickType={'hour'}
              />
            </div>
            <div>
              <div className={'divider'} />
              <h1>신살</h1>
              <p className={'descriptions'}>
                신살은 당신의 사주팔자에 놓여있는 좋은 길신과 나쁜 흉살을
                의미합니다. 신살 파트에서는 오늘 당신에게 어떤 신살이 들어왔는지
                보여 줍니다.
              </p>
              <EmptySpace height={'10px'} />
              {todayAnalysis.sinSals.map((sinSal, index) => (
                <div key={index}>
                  <h2>{sinSal.name}</h2>
                  <p className={'description'}>{sinSal.description}</p>
                  <EmptySpace height={'10px'} />
                </div>
              ))}
            </div>
          </div>
        ) : undefined}
      </Container>
    </div>
  )
}

export default TodayContainer
